<template>
    <div class="con-wrap">
        <DeleteHistoryPopup v-if="pop.isShow" @close="pop.isShow=false"></DeleteHistoryPopup>
        <CarrotTitle title="퇴직자 관리">
            - 퇴직자 이름 클릭 시 인사카드 페이지로 이동됩니다.<br>
            - 퇴직자의 개인정보는 퇴직일 기준 3년까지 보관됩니다.
        </CarrotTitle>
        <div class="board">
            <div v-if="hq.isAuth=='Y'" class="mt-40">
                <div class="mt-50 mb-20">
                    <table class="table-row table-serach">
                        <tbody>
                            <tr>
                                <th width="180">퇴직일</th>
                                <td>
                                    <CarrotDatePicker v-model="hq.sdate" class="w-110px float-left"></CarrotDatePicker>
                                    <span class="float-left ml-10 mr-10">~</span>
                                    <CarrotDatePicker v-model="hq.edate" class="w-110px float-left"></CarrotDatePicker>
                                </td>
                                <th width="180">연락처</th>
                                <td>
                                    <input type="text" class="w-100per" v-model.trim="hq.phone">
                                </td>
                            </tr>
                            <tr>
                                <th width="180">이름</th>
                                <td>
                                    <input type="text" class="w-100per" v-model.trim="hq.kname">
                                </td>
                                <th width="180">영문이름</th>
                                <td>
                                    <input type="text" class="w-100per" v-model.trim="hq.ename">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button class="btn-default float-right mt-10" @click="hq.doSearch">검색</button>
                    <div class="clear"></div>
                </div>
                <div class="mt-40 mb-20">
                    <CarrotDept v-model="hq.idx_office" @change="hq.doSearchTeam" class="w-200px"></CarrotDept>
                    <CarrotTeam :idx_office="hq.idx_office" v-model="hq.idx_team" @change="hq.doSearch" class="w-200px ml-5"></CarrotTeam>
                    <button class="btn-default float-right ml-10" @click="hq.downExcel">엑셀 다운로드</button>
                    <div class="clear"></div>
                </div>
                
                <table class="table-col">
                    <colgroup>
                    </colgroup>
                    <thead>
                        <tr>
                          <th width="60" >No</th>
                          <th width="180">이름
                            <span style="cursor:pointer" v-if="hq.orderby!='nameasc' && hq.orderby!='namedesc'" @click="hq.doSearchOrderBy('nameasc')" class="fas fa-arrow-up"></span>
                            <span style="cursor:pointer" v-if="hq.orderby!='nameasc' && hq.orderby!='namedesc'"  @click="hq.doSearchOrderBy('nameasc')"  class="fas fa-arrow-down"></span>
                            <span style="cursor:pointer" v-if="hq.orderby=='nameasc' " @click="hq.doSearchOrderBy('namedesc')" class="fas fa-arrow-up"></span>
                            <span style="cursor:pointer" v-if="hq.orderby=='namedesc'"  @click="hq.doSearchOrderBy('nameasc')"  class="fas fa-arrow-down"></span>
                          </th>
                          <th>직급
                            <span style="cursor:pointer" v-if="hq.orderby!='positionasc' && hq.orderby!='positiondesc'" @click="hq.doSearchOrderBy('positionasc')" class="fas fa-arrow-up"></span>
                            <span style="cursor:pointer" v-if="hq.orderby!='positionasc' && hq.orderby!='positiondesc'"  @click="hq.doSearchOrderBy('positionasc')"  class="fas fa-arrow-down"></span>
                            <span style="cursor:pointer" v-if="hq.orderby=='positionasc' " @click="hq.doSearchOrderBy('positiondesc')" class="fas fa-arrow-up"></span>
                            <span style="cursor:pointer" v-if="hq.orderby=='positiondesc'"  @click="hq.doSearchOrderBy('positionasc')"  class="fas fa-arrow-down"></span>
                          </th>
                          <th>부문
                            <span style="cursor:pointer" v-if="hq.orderby!='sectorasc' && hq.orderby!='sectordesc'" @click="hq.doSearchOrderBy('sectorasc')" class="fas fa-arrow-up"></span>
                            <span style="cursor:pointer" v-if="hq.orderby!='sectorasc' && hq.orderby!='sectordesc'"  @click="hq.doSearchOrderBy('sectorasc')"  class="fas fa-arrow-down"></span>
                            <span style="cursor:pointer" v-if="hq.orderby=='sectorasc' " @click="hq.doSearchOrderBy('sectordesc')" class="fas fa-arrow-up"></span>
                            <span style="cursor:pointer" v-if="hq.orderby=='sectordesc'"  @click="hq.doSearchOrderBy('sectorasc')"  class="fas fa-arrow-down"></span>
                          </th>
                          <th width="180">본부
                            <span style="cursor:pointer" v-if="hq.orderby!='officeasc' && hq.orderby!='officedesc'" @click="hq.doSearchOrderBy('officeasc')" class="fas fa-arrow-up"></span>
                            <span style="cursor:pointer" v-if="hq.orderby!='officeasc' && hq.orderby!='officedesc'"  @click="hq.doSearchOrderBy('officeasc')"  class="fas fa-arrow-down"></span>
                            <span style="cursor:pointer" v-if="hq.orderby=='officeasc' " @click="hq.doSearchOrderBy('officedesc')" class="fas fa-arrow-up"></span>
                            <span style="cursor:pointer" v-if="hq.orderby=='officedesc'"  @click="hq.doSearchOrderBy('officeasc')"  class="fas fa-arrow-down"></span>
                          </th>
                          <th>팀
                            <span style="cursor:pointer" v-if="hq.orderby!='teamasc' && hq.orderby!='teamdesc'" @click="hq.doSearchOrderBy('teamasc')" class="fas fa-arrow-up"></span>
                            <span style="cursor:pointer" v-if="hq.orderby!='teamasc' && hq.orderby!='teamdesc'"  @click="hq.doSearchOrderBy('teamasc')"  class="fas fa-arrow-down"></span>
                            <span style="cursor:pointer" v-if="hq.orderby=='teamasc' " @click="hq.doSearchOrderBy('teamdesc')" class="fas fa-arrow-up"></span>
                            <span style="cursor:pointer" v-if="hq.orderby=='teamdesc'"  @click="hq.doSearchOrderBy('teamasc')"  class="fas fa-arrow-down"></span>
                          </th>
                          <th>소속법인
                            <span style="cursor:pointer" v-if="hq.orderby!='corpasc' && hq.orderby!='corpdesc'" @click="hq.doSearchOrderBy('corpasc')" class="fas fa-arrow-up"></span>
                            <span style="cursor:pointer" v-if="hq.orderby!='corpasc' && hq.orderby!='corpdesc'"  @click="hq.doSearchOrderBy('corpasc')"  class="fas fa-arrow-down"></span>
                            <span style="cursor:pointer" v-if="hq.orderby=='corpasc' " @click="hq.doSearchOrderBy('corpdesc')" class="fas fa-arrow-up"></span>
                            <span style="cursor:pointer" v-if="hq.orderby=='corpdesc'"  @click="hq.doSearchOrderBy('corpasc')"  class="fas fa-arrow-down"></span>
                          </th>
                          <th>생년월일
                            <span style="cursor:pointer" v-if="hq.orderby!='birthasc' && hq.orderby!='birthdesc'" @click="hq.doSearchOrderBy('birthasc')" class="fas fa-arrow-up"></span>
                            <span style="cursor:pointer" v-if="hq.orderby!='birthasc' && hq.orderby!='birthdesc'"  @click="hq.doSearchOrderBy('birthasc')"  class="fas fa-arrow-down"></span>
                            <span style="cursor:pointer" v-if="hq.orderby=='birthasc' " @click="hq.doSearchOrderBy('birthdesc')" class="fas fa-arrow-up"></span>
                            <span style="cursor:pointer" v-if="hq.orderby=='birthdesc'"  @click="hq.doSearchOrderBy('birthasc')"  class="fas fa-arrow-down"></span>
                          </th>
                          <th>입사일
                            <span style="cursor:pointer" v-if="hq.orderby!='joindayasc' && hq.orderby!='joindaydesc'" @click="hq.doSearchOrderBy('joindayasc')" class="fas fa-arrow-up"></span>
                            <span style="cursor:pointer" v-if="hq.orderby!='joindayasc' && hq.orderby!='joindaydesc'"  @click="hq.doSearchOrderBy('joindayasc')"  class="fas fa-arrow-down"></span>
                            <span style="cursor:pointer" v-if="hq.orderby=='joindayasc' " @click="hq.doSearchOrderBy('joindaydesc')" class="fas fa-arrow-up"></span>
                            <span style="cursor:pointer" v-if="hq.orderby=='joindaydesc'"  @click="hq.doSearchOrderBy('joindayasc')"  class="fas fa-arrow-down"></span>
                          </th>
                            <th>퇴직일
                              <span style="cursor:pointer" v-if="hq.orderby!='resignationasc' && hq.orderby!='resignationdesc'" @click="hq.doSearchOrderBy('resignationasc')" class="fas fa-arrow-up"></span>
                              <span style="cursor:pointer" v-if="hq.orderby!='resignationasc' && hq.orderby!='resignationdesc'"  @click="hq.doSearchOrderBy('resignationasc')"  class="fas fa-arrow-down"></span>
                              <span style="cursor:pointer" v-if="hq.orderby=='resignationasc' " @click="hq.doSearchOrderBy('resignationdesc')" class="fas fa-arrow-up"></span>
                              <span style="cursor:pointer" v-if="hq.orderby=='resignationdesc'"  @click="hq.doSearchOrderBy('resignationasc')"  class="fas fa-arrow-down"></span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(irow, i) in hq.list" :key="i">
                            <td>{{ irow.num }}</td>
                            <td class="txt-ellipsis"><router-link :to="{ name : 'myPIMS-PersonalInfoList_tab1-idx', params : { idx:irow.idx }}" target="_blank"><span class="btn-view">{{ irow.ename }} ({{ irow.kname }})</span></router-link></td>
                            <td class="txt-ellipsis">{{ irow.position_name }}</td>
                            <td class="txt-ellipsis">{{ irow.sector_name }}</td>
                            <td class="txt-ellipsis">{{ irow.office_name }}</td>
                            <td class="txt-ellipsis" >{{ irow.team_name }}</td>
                            <td class="txt-ellipsis">{{ irow.corporation_name }}</td>
                            <td>{{ irow.birth }}</td>
                            <td>{{ irow.joindate }}</td>
                            <td>{{ irow.resignationday }}</td>
                        </tr>
                        <tr v-if="hq.total==0">
                            <td colspan="9">조건에 맞는 퇴직자를 찾을 수 없습니다.</td>
                        </tr>
                    </tbody>
                </table>
                <CarrotPaging :total="hq.total" :list_per_page="hq.rows" v-model="hq.page" @change="hq.doSearch"></CarrotPaging>

            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotDept from '@/components/common/CarrotDept.vue'
import CarrotTeam from '@/components/common/CarrotTeam.vue'
import DeleteHistoryPopup from '@/components/popup/myPIMS/DeleteHistoryPopup.vue'
import { useStore } from 'vuex';


export default {
    layout:"myPIMS",
    components: {
        CarrotDatePicker,
        CarrotDept,
        CarrotTeam,
        DeleteHistoryPopup
    },
    setup() {
        const router = new useRouter();
        const toast  = useToast();
        const store  = useStore();

        const pop = reactive({
            // Popup 노출 여부
            isShow: false
        });

        const hq = reactive({
            isAuth : "N",

            page   : 1,
            rows   : 1000,
            sdate  : "",
            edate  : "",
            phone  : "",
            kname  : "",
            ename  : "",
            state  : "QUIT",
            idx_office : 0,
            idx_team   : 0,
            orderby:"",
            analysis : [],
            list : [], total : 0,

            doSearchTeam : () => {
                hq.doSearch();
            },

            downExcel : () => {
                let url = "https://api.carrotians.net/excel/retiree_management_list?sdate=" + hq.sdate + "&edate=" + hq.edate + "&phone=" + hq.phone + "&kname=" + hq.kname + "&ename=" + hq.ename + "&idx_office=" + hq.idx_office + "&idx_team=" + hq.idx_team;
                window.open(url, "_blank").focus();
            },

            viewHQ : (idx) => {
                router.push({
                    name   : 'myPIMS-PersonalInfoList_tab1-idx',
                    params : { idx:idx }
                });
            },
            doSearchOrderBy : (orderby) => {
              hq.orderby=orderby;
              hq.doSearch();
            },
            doSearchTotal : () => {
                let params = {
                    state : hq.state
                };

                axios.get('/rest/mypims/getQuitAnalysis', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        hq.analysis = res.data;
                        hq.isAuth   = res.data.is_auth;

                        if( hq.isAuth == "N" ){
                            Swal.fire({
                                title : '퇴직자관리',
                                text  : '권한이 없습니다.'
                            }).then(() => {
                                router.go(-1);
                            });
                        }
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSearch : () => {
                let params = {
                    page   : hq.page,
                    rows   : hq.rows,
                    sdate  : hq.sdate,
                    edate  : hq.edate,
                    phone  : hq.phone,
                    kname  : hq.kname,
                    ename  : hq.ename,
                    idx_office : hq.idx_office,
                    idx_team   : hq.idx_team,
                    orderby   : hq.orderby
                };

                axios.get('/rest/mypims/getQuitList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        hq.list   = res.data.list;
                        hq.total  = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        onMounted(() => {
            // Mounted
            // 현재는 팀 구성이 되어있지 않으므로 스탭을 지정하여 체크하도록 변경, 3995:슈리, 19054:헤이즐
            if( store.state.position != '리드' &&  store.state.pd_auth == false  ) {
                router.go(-1);
                Swal.fire({
                    title : '퇴직자 관리',
                    text  : '접근 권한이 없습니다.'
                });
                return;
            }

            hq.doSearch();
            hq.doSearchTotal();
        });

        return {pop, hq};
    }
}
</script>

<style lang="scss" scoped>
</style>