<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-400px">
            <div>
                <p class="title">삭제이력</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <table class="tbl-light-list">
                    <colgroup>
                        <col width="*">
                        <col width="120">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>본부/팀</th>
                            <th>삭제일</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(irow, i) in pop.list" :key="i">
                            <td>{{ irow.log }}</td>
                            <td>{{ irow.wdate }}</td>
                        </tr>
                        <!-- <tr>
                            <td>해외본부</td>
                            <td>2020-01-13</td>
                        </tr>
                        <tr>
                            <td>글로벌팀</td>
                            <td>2020-01-13</td>
                        </tr>
                        <tr>
                            <td>Dream</td>
                            <td>2020-01-13</td>
                        </tr>
                        <tr>
                            <td>미디어팀</td>
                            <td>2020-01-13</td>
                        </tr> -->
                    </tbody>
                </table>
                <CarrotPaging :total="pop.total" :list_per_page="pop.rows" v-model="pop.page" @change="pop.doSearch"></CarrotPaging>

            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import axios from '@/plugins/axios.js'

export default {
    name: 'DeleteHistoryPopup',
    props: {
        idx: {
            type: Number,
            default: 0,
        }
    },
    setup(props, {emit}) {
        const pop = reactive({
            page : 1,
            rows : 20,
            idx  : 0,

            list : [], total : 0,

            doSearch : () => {
                let params = {
                    page : pop.page,
                    rows : pop.rows
                };
                axios.get("/rest/mypims/getDeleteHistory", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        pop.list  = res.data.list;
                        pop.total = res.data.total;
                    } else {
                        console.info(res);
                    }
                });
            },

            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            pop.idx = props.idx;

            pop.doSearch();
        })
        
        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>